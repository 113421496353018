import { HttpClient } from '@angular/common/http';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, firstValueFrom, Observable, skipWhile, Subscription, switchMap } from 'rxjs';
import { RequestCache } from './cache';
import { ConnectivityService } from '@logic-suite/shared/connectivity/connectivity.service';
import { ApplicationStorageService } from './storage/application-storage.service';
import { getEnv } from './utils/getEnv';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class AppService implements OnDestroy {
  private connectivityService = inject(ConnectivityService);

  // Proxy to storage service
  private storage = inject(ApplicationStorageService);
  getItem = (identifier: string, defaultVal: any = '') => this.storage.getItem(identifier, defaultVal);
  hasItem = (identifier: string) => this.storage.hasItem(identifier);
  setItem = (identifier: string, value: any) => this.storage.setItem(identifier, value);
  removeItem = (identifier: string, values?: Record<string, unknown>) => this.storage.removeItem(identifier, values);
  replaceItem = (identifier: string, value: any, acc?: Record<string, unknown>) =>
    this.storage.replaceItem(identifier, value, acc);

  private _application$ = new BehaviorSubject<number>(0);
  get application$() {
    return this._application$.asObservable();
  }

  activeApplication = toSignal(this._application$);
  private _version$ = new BehaviorSubject<string | undefined>(undefined);

  private previousUrl?: string;
  private currentUrl?: string;

  private subscriptions: Subscription[] = [];

  titleChanged$ = new BehaviorSubject<string>('');

  constructor(
    private http: HttpClient,
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private cache: RequestCache,
  ) {
    // Auto read version for each app using this library, and set as attribute on document body
    this.loadVersion().subscribe((res) => {
      document.body.setAttribute('version', res.trim());
      this._version$.next(res.trim());
    });

    // Track routes
    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((e) => e instanceof NavigationEnd),
          switchMap(() => this.route.queryParams),
        )
        .subscribe(() => {
          if (this.router.url.split('?')[0] !== this.currentUrl?.split('?')[0]) {
            // Only set previous url if the route has actually changed.
            // This excludes changes in query params
            const newParentRoute = this.router.url.substring(0, this.router.url.lastIndexOf('/'));
            const curParentRoute = this.currentUrl?.substring(0, this.currentUrl?.lastIndexOf('/'));
            if (!(this.currentUrl?.endsWith('/new') && newParentRoute === curParentRoute)) {
              // If we are redirecting from '/new' to an actual stored id, just replace url so we can
              // safely go back
              this.previousUrl = this.currentUrl;
            }
          }
          // Always replace current url, even with changes in query params
          // This will respect search and filtering
          this.currentUrl = this.router.url;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  getPreviousUrl() {
    return this.previousUrl != null ? decodeURIComponent(this.previousUrl) : undefined;
  }

  getCurrentUrl() {
    return this.currentUrl;
  }

  private loadVersion(): Observable<string> {
    return this.http.get(`assets/version.txt?d=${new Date().getTime()}`, {
      headers: { NoLoad: 'true' },
      responseType: 'text',
    });
  }

  getVersion() {
    return firstValueFrom(this._version$.pipe(skipWhile((v) => !v)));
  }

  getApplicationID(): number {
    return this._application$.value;
  }

  getTitleBase(): string {
    // prettier-ignore
    switch (this.getApplicationID()) {
      case 10:
        return 'Flex';
      case 11:
        return 'Flx Admin';
      case 12:
        return 'Flx Terminal';
      case 20:
        return 'Logic BS';
    }
    return 'Noova Energy';
  }

  getApplicationName(): string {
    // prettier-ignore
    switch (this.getApplicationID()) {
      // Logic Suite
      case 0:
        return 'Noova Energy';
      case 1:
        return 'Energy';
      case 2:
        return 'Notifications';
      case 3:
        return 'Sensors';
      case 4:
        return 'Facility';
      case 5:
        return 'Dashboard';
      // FLEX
      case 10:
        return 'Flx';
      case 11:
        return 'Flx Admin';
      case 12:
        return 'Flx Terminal';
      // LBS
      case 20:
        return 'Logic Business Solutions';
    }
    return '';
  }

  setApplicationID(id: number) {
    if (this._application$.value !== id) {
      this._application$.next(id);
    }
  }

  perfMark(name: string) {
    if (getEnv('production') === false) {
      performance.mark(name);
    }
  }

  perfEndAndMesure(name: string) {
    if (getEnv('production') === false) {
      performance.mark(name + 'End');
      performance.measure(name + 'Measure', name, name + 'End');
      // eslint-disable-next-line no-restricted-syntax
      console.info(name, performance.getEntriesByName(name + 'Measure')[0].duration);
      performance.clearMarks(name);
      performance.clearMarks(name + 'End');
      performance.clearMeasures(name + 'Measure');
    }
  }

  setTitle(part: string) {
    const title = this.getTitleBase();
    this.title.setTitle(part ? `${part} | ${title}` : title);
    this.titleChanged$.next(this.getTitle());
  }

  getTitle(): string {
    return this.title.getTitle();
  }

  invalidateCache() {
    this.cache.invalidate();
  }
}
