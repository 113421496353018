import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';

import { DateHelperService } from './date-helper.service';
import { LanguageSelectorComponent } from './language-selector.component';
import { LanguageService } from './language.service';
import { LogicDateAdapter } from './logic-date-adapter';
import { LogicDatePipe } from './logic-date.pipe';

@NgModule({
  imports: [CommonModule, LanguageSelectorComponent, LogicDatePipe],
  exports: [LanguageSelectorComponent, LogicDatePipe],
})
export class I18nModule {}

/**
 * Should only be imported in your root module
 */
@NgModule({
  imports: [MatNativeDateModule, I18nModule],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: (lang: LanguageService) => lang.getLocale(),
      deps: [LanguageService],
    },
    { provide: DateAdapter, useClass: LogicDateAdapter },
    DateHelperService,
  ],
})
export class I18nRootModule {}
