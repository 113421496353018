import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RequestCache } from '@logic-suite/shared/cache';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { BehaviorSubject, filter, map, pairwise, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SiteService {
  private readonly storage = inject(ApplicationStorageService);
  private cache = inject(RequestCache);
  availableSites = ['no', 'se'];

  selectedSite$ = new BehaviorSubject(this.storage.getItem('site', 'no'));
  selectedSite = toSignal(this.selectedSite$);
  siteChanged$ = this.selectedSite$.pipe(
    pairwise(),
    filter(([a, b]) => a !== b),
    tap(() => this.cache.invalidate()),
    map(([a, b]) => b)
  );

  constructor() {
    this.siteChanged$.subscribe(val => {
      this.storage.replaceItem('site', val);
    });
  }
}
