/**
 * @deprecated use dateUtils.msToDate instead.
 * Note that this new method does not work the same way.
 */
export function millisToDate(num?: number | Date): Date {
  if (num == null || num instanceof Date) return num as Date;
  return (num != null ? new Date(num) : num) as Date;
}

/**
 * @deprecated use dateUtils.dateToMs instead.
 * Note that this new method does not work the same way.
 */
export function dateToMillis(date?: Date | number) {
  if (date == null || typeof date === 'number') return date as number;
  return (date instanceof Date ? date.getTime() : date) as number;
}

// TODO(dev): Move this to dateUtils. Add tests.
export function getUserLocaleDateFormat() {
  const userLocaleDate = new Date().toLocaleDateString();
  const knownFormatDate = new Date().toLocaleDateString('en-US');

  const dateDelimiter = ['/', '-', '.', ' '].find((delimiter) => userLocaleDate.includes(delimiter))!;
  const userDateParts = userLocaleDate.split(dateDelimiter);
  const [knownMonth, knownDay, knownYear] = knownFormatDate.split('/');
  const dayIndex = userDateParts.findIndex((part) => part === knownDay);
  const monthIndex = userDateParts.findIndex((part) => part === knownMonth);
  const yearIndex = userDateParts.findIndex((part) => part === knownYear);
  return [dayIndex, monthIndex, yearIndex].map((index) => ['dd', 'MM', 'yyyy'][index]).join(dateDelimiter);
}
