import { Pipe, PipeTransform } from '@angular/core';
import { numberFormat } from '../utils/numberFormatter';

@Pipe({
  name: 'numeric',
  standalone: true,
})
export class NumericPipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    return numberFormat(value, ...args);
  }
}
