import { Pipe, PipeTransform } from '@angular/core';
import * as stringUtils from '../utils/stringUtils';

@Pipe({
  name: 'case',
  standalone: true,
})
export class CaseConvertPipe implements PipeTransform {
  transform(value: unknown, type?: string): string {
    if (!value || typeof value !== 'string') return !value ? '' : value.toString();
    if (type) {
      switch (type) {
        case 'camel':
          return stringUtils.camelCase(value);
        case 'title':
          return stringUtils.titleCase(value).replace('Id', 'ID');
        case 'pascal':
          return stringUtils.pascalCase(value);
        case 'sentence':
          return stringUtils.sentenceCase(value);
        case 'hyphenate':
          return stringUtils.hyphenate(value);
        case 'underscore':
          return stringUtils.underscore(value);
        case 'lower':
          return stringUtils.lowerCase(value);
        case 'upper':
          return stringUtils.upperCase(value);
        default:
          return value;
      }
    }
    return stringUtils.sentenceCase(value);
  }
}
