import { Injectable } from '@angular/core';

const ALL_ENVIRONMENTS = ['dev', 'test', 'prod'];
type EnvironmentTuple = typeof ALL_ENVIRONMENTS;
export type Environment = EnvironmentTuple[number];

/**
 * Service for accessing environment variables stored in window.__env.
 *
 * To add a new environment variable:
 * 1. Update nginx.conf:
 *    - Add variable under "Fetch environment variables".
 *    - Add variable under "Returns secrets to the client".
 * 2. In fetch.js:
 *    - Add function to return the new variable.
 *    - Add the function to the list of default exports.
 * 3. In WA-LogicRoot-Dev Azure Resource:
 *    - Navigate to Settings > Environment variables.
 *    - Add the new variable with the same name as in the code.
 * 4. In this service:
 *    - Add the appropriate typesafe variables.
 *    - Add a method to get the variable.
 *    - Add a method to validate the variable.
 */
@Injectable({ providedIn: 'root' })
export class EnvService {
  isDev(): boolean {
    return this.getEnv() === 'dev' || location.href.includes('localhost');
  }

  getEnv(): Environment {
    const env = this.getEnvironmentVariable<Environment>('env');

    return this.isValidEnvironment(env) ? env : 'dev';
  }

  private isValidEnvironment(env: Environment | null): env is Environment {
    return ALL_ENVIRONMENTS.includes(env as Environment);
  }

  private getEnvironmentVariable<T>(key: string): T | null {
    try {
      return ((window as any).__env?.[key] as T) ?? null;
    } catch (err) {
      console.error(`Could not get ${key} from environment variables.`, 'EnvService', err);
      return null;
    }
  }
}
