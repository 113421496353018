import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumericPipe } from './numeric.pipe';
import { CaseConvertPipe } from './case-convert.pipe';
import { FilterPipe } from './filter.pipe';
import { AbbreviatePipe } from './abbreviate.pipe';

@NgModule({
  declarations: [],
  imports: [CommonModule, NumericPipe, CaseConvertPipe, FilterPipe, AbbreviatePipe],
  exports: [NumericPipe, CaseConvertPipe, FilterPipe, AbbreviatePipe],
})
export class PipesModule {}
