import { Pipe, PipeTransform } from '@angular/core';
import { SiteService } from '../../shared/site-selector/site.service';

export const NORWEGIAN_METER_PREFIX = '7070575000';
export const SWEDISH_METER_PREFIX = '735999';

export function formatMeter(meterID: string, site = 'no'): string {
  switch (site) {
    case 'no':
      return `${meterID}`.replace(NORWEGIAN_METER_PREFIX, '');
    case 'se':
      return `${meterID}`.replace(SWEDISH_METER_PREFIX, '');
  }
  return meterID;
}

@Pipe({
  name: 'meter',
  standalone: true,
})
export class MeterPipe implements PipeTransform {
  constructor(private site: SiteService) {}

  transform(value: any) {
    return formatMeter(`${value}`, this.site.selectedSite());
  }
}
