import { inject, Injectable } from '@angular/core';
import { Environment, EnvService } from '@logic-suite/shared/env/env.service';

type LogLevel = 'DEBUG' | 'INFO' | 'WARN' | 'ERROR';

@Injectable({ providedIn: 'root' })
export class Logger {
  private envService = inject(EnvService);

  private logLevelColors: Record<LogLevel, string> = {
    DEBUG: '#95a5a6',
    INFO: '#3498db',
    WARN: '#e67e22',
    ERROR: '#e74c3c',
  };

  private activeLogLevels: Set<LogLevel>;

  constructor() {
    this.activeLogLevels = this.getActiveLogLevels(this.envService.getEnv());
  }

  private getActiveLogLevels(env: Environment): Set<LogLevel> {
    switch (env) {
      case 'dev':
        return new Set(['DEBUG', 'INFO', 'WARN', 'ERROR']);
      case 'test':
        return new Set(['INFO', 'WARN', 'ERROR']);
      case 'prod':
        return new Set(['WARN', 'ERROR']);
      default:
        return new Set(['ERROR']);
    }
  }

  private log(level: LogLevel, message: string, source?: string, ...args: any[]): void {
    if (!this.activeLogLevels.has(level)) {
      return;
    }

    const color = this.logLevelColors[level];
    const prefix = `%c${level}`;
    const style = `background: ${color}; color: white; padding: 2px 4px; border-radius: 2px;`;

    const sourcePrefix = source ? `[${source}] ` : '';
    console.log(prefix, style, `${sourcePrefix}${message}`, ...args);
  }

  debug(message: string, source?: string, ...args: any[]): void {
    this.log('DEBUG', message, source, ...args);
  }

  info(message: string, source?: string, ...args: any[]): void {
    this.log('INFO', message, source, ...args);
  }

  warn(message: string, source?: string, ...args: any[]): void {
    this.log('WARN', message, source, ...args);
  }

  error(message: string, source?: string, ...args: any[]): void {
    this.log('ERROR', message, source, ...args);
  }
}
