import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { deepMerge } from '../utils';

export abstract class AbstractStorage {
  values: Record<string, unknown> = {};
  private listeners = new Map<string, Subject<any>>();

  hasItem(identifier: string): boolean {
    try {
      const value =
        identifier.indexOf('.') > -1
          ? identifier.split('.').reduce((acc: any, key: string) => acc[key], this.values)
          : this.values[identifier];
      return value != null;
    } catch (ex) {
      return false;
    }
  }

  /**
   * Get an item from storage
   *
   * @param identifier can be a json path
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItem(identifier: string, defaultVal: any = ''): any {
    const value =
      identifier.indexOf('.') > -1
        ? identifier.split('.').reduce((acc, key, idx, arr) => {
            if (idx >= arr.length - 1 && defaultVal && acc[key] != null && typeof defaultVal !== typeof acc[key]) {
              // Stored value is not of expected type. Remove it.
              acc[key] = undefined;
            }
            return acc[key] != null ? acc[key] : idx < arr.length - 1 ? {} : defaultVal;
          }, this.values)
        : this.values[identifier] != null
        ? this.values[identifier]
        : defaultVal;
    // if (!value) console.error('Value could not be returned');
    return value;
  }

  /**
   * Watch for changes in a particular identifier and react.
   */
  getItemChanges(identifier: string, defaultVal: any = ''): Observable<any> {
    if (this.listeners.has(identifier)) {
      return this.listeners.get(identifier)?.asObservable() as Observable<any>;
    }
    const initial = this.getItem(identifier, defaultVal);
    const subj = new BehaviorSubject(initial);
    this.listeners.set(identifier, subj);
    return subj;
  }

  /**
   * Stores an item in our storage object in sessionStorage.
   *
   * @param identifier can be a json path
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItem(identifier: string, value: any) {
    if (identifier.indexOf('.') > -1) {
      // Identifier is a json path. Value must be deepmerged
      const obj: Record<string, unknown> = {};
      const keys = identifier.split('.');
      keys.reduce((acc: any, key: string, idx: number, arr: string[]) => {
        acc[key] = idx < arr.length - 1 ? {} : value;
        return acc[key];
      }, obj);
      this.values = deepMerge(this.values, obj) as Record<string, unknown>;
    } else if (['string', 'number', 'boolean'].includes(typeof value) || Array.isArray(value)) {
      // Single property identifier, and value is a primitive or array.
      this.values[identifier] = value;
    } else {
      // Single property identifier, and value is an object.
      this.values[identifier] = Object.assign(this.getItem(identifier, {}), value);
    }
    if (this.listeners.has(identifier)) {
      // Fire listeners if anybody is interested
      this.listeners.get(identifier)?.next(value);
    }
    this.storeCurrent();
  }

  /**
   * Remove a value from storage
   *
   * @param identifier can be a json path
   */
  removeItem(identifier: string, acc = this.values) {
    if (identifier.indexOf('.') > -1) {
      const keys = identifier.split('.');
      // One or more keys exist in this bucket.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.removeItem(keys.slice(1).join('.'), (acc as any)[keys[0]]);
      // if (Object.keys(acc).length <= 1) {
      //   delete acc[keys[0]];
      // }
    } else {
      delete acc[identifier];
    }
    if (this.listeners.has(identifier)) {
      this.listeners.get(identifier)?.next(null);
    }
    this.storeCurrent();
  }

  replaceItem(identifier: string, value: any, acc = this.values) {
    this.removeItem(identifier, acc);
    this.setItem(identifier, value);
    this.storeCurrent();
  }

  protected abstract storeCurrent(): void;

  abstract reset(): void;
}
