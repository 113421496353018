import { Injectable } from '@angular/core';
import { DateHelperService } from '../i18n/date-helper.service';
import { AbstractStorage } from '../storage/abstract-storage';
import { numberFormat } from '../utils/numberFormatter';
import { objToString } from '../utils/stringUtils';
import { TableColumn } from './table.config';

@Injectable({ providedIn: 'root' })
export class TableService extends AbstractStorage {
  private storageKey = 'myTables';

  constructor(private dateFormat: DateHelperService) {
    super();

    // Load and decode personal settings
    let valueStr = sessionStorage.getItem(this.storageKey) || '{}';
    try {
      valueStr = atob(valueStr);
    } catch (ex) {
      const err = 'DEBUG: Cannot parse previous settings. Starting blank...';
    }
    this.values = JSON.parse(valueStr);
  }
  /**
   * Encode and store values
   */
  protected storeCurrent() {
    sessionStorage.setItem(this.storageKey, btoa(objToString(this.values)));
  }

  reset() {
    sessionStorage.removeItem(this.storageKey);
  }

  formatValue(column: TableColumn, value: any) {
    // prettier-ignore
    switch (column.type) {
      case 'number': value = numberFormat(value, 'decimal', column.decimals || 2); break;
      case 'percent': value = numberFormat(value, 'percent', column.decimals || 2); break;
      case 'date': value = this.dateFormat.format(value); break;
    }
    return value;
  }
}
