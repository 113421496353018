import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from './language.service';
import { DateHelperService } from './date-helper.service';

@Pipe({ name: 'logicDate', pure: false, standalone: true })
export class LogicDatePipe implements PipeTransform, OnDestroy {
  subscription: Subscription;
  private markForTransform = true;
  private value!: any;

  constructor(
    private dateService: DateHelperService,
    private lang: LanguageService,
  ) {
    this.subscription = this.lang.onLangChange.subscribe(() => (this.markForTransform = true));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  transform(value: Date | number, args?: string): string {
    if (!this.markForTransform) return this.value;
    if (value == null) return '';
    return this.dateService.format(value as Date, args);
  }
}
