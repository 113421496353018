import { Inject, Injectable, inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { format, isValid, toDate } from 'date-fns';
import { LanguageService } from './language.service';

@Injectable()
export class DateHelperService {
  lang = inject(LanguageService);
  constructor(
    private dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
  ) {}

  format(date: Date | number, formatStr?: string): string {
    try {
      if (!isValid(date)) return '' + date;
      if (formatStr != null) {
        return format(toDate(date as number | Date), formatStr, { locale: this.lang.dateLocale, weekStartsOn: 1 });
      }
      return this.dateAdapter.format(toDate(date as number | Date), this.dateFormats);
    } catch (ex) {
      return '' + date;
    }
  }
}
