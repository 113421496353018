import { fromEvent, map, merge, Observable } from 'rxjs';
import { computed, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Logger } from '@logic-suite/shared/logger/logger.service';
import { distinctUntilChanged, tap } from 'rxjs/operators';

export enum Connectivity {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

interface ConnectivityServiceState {
  connectivity: Connectivity;
}

@Injectable({ providedIn: 'root' })
export class ConnectivityService {
  private logger = inject(Logger);

  // Sources
  private onlineEvent$: Observable<Event> = fromEvent(window, Connectivity.ONLINE);
  private offlineEvent$: Observable<Event> = fromEvent(window, Connectivity.OFFLINE);
  connectivityChanged$ = merge(this.onlineEvent$, this.offlineEvent$).pipe(
    map((event) => event.type as Connectivity),
    distinctUntilChanged(),
    tap((connectivity) => this.logger.info(`Connectivity changed: ${connectivity}`, 'ConnectivityService')),
  );

  // State
  private readonly state = signal<ConnectivityServiceState>({
    connectivity: window.navigator.onLine ? Connectivity.ONLINE : Connectivity.OFFLINE,
  });

  // Selectors
  connectivity = computed(() => this.state().connectivity);
  // Computed selectors
  isOnline = computed(() => this.state().connectivity === Connectivity.ONLINE);
  isOffline = computed(() => this.state().connectivity === Connectivity.OFFLINE);

  constructor() {
    // Reducers
    this.connectivityChanged$.pipe(takeUntilDestroyed()).subscribe((connectivity) => {
      this.state.update((state) => ({
        ...state,
        connectivity,
      }));
    });
  }
}
