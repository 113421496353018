import { Cookie } from './cookies';
import { getLocalStorage } from './getLocalStorage';
import { logger } from './logger';
import { errorToString } from './stringUtils';

/**
 * Clears out the cache and the service worker.
 *
 * @returns `true` if successful, otherwise an error message.
 */
export async function resetClient() {
  let error = '';
  // Clear cookies
  Cookie.clear();

  // Clear local storage
  getLocalStorage().clear();

  // Clear cache
  const cacheRes = await clearCacheOnly();
  error += cacheRes !== true ? cacheRes : error;

  // Clear SW
  const swRes = await clearSWOnly();
  error += swRes !== true ? swRes : error;

  // Return result
  return error != '' ? error : true;
}

export interface ClearCacheOptions {
  /**
   * Keys matching this will be excluded from the cache clean operation
   * and remain in the cache after the operation completes.
   **/
  exclude?: RegExp[];
}

/**
 * Clear the Cache Storage
 *
 * @param opts
 * @returns true if successful, otherwise an error message.
 */
export async function clearCacheOnly(opts: ClearCacheOptions = {}) {
  if ('caches' in self) {
    try {
      const cacheNames = await caches.keys();
      cacheNames.forEach(cacheName => {
        if (!opts.exclude || !opts.exclude.some(key => key.test(cacheName))) {
          caches.delete(cacheName);
        }
      });

      return true;
    } catch (ex) {
      return logger(errorToString(ex), 'App', true);
    }
  } else {
    logger('Cache not supported', 'App', true);
    return false;
  }
}

/**
 * Unregister any service worker on this domain
 *
 * @returns true if successful, otherwise an error message.
 */
export async function clearSWOnly() {
  if ('serviceWorker' in navigator) {
    try {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        registration.unregister();
      }
      return true;
    } catch (ex) {
      return logger(errorToString(ex), 'App', true);
    }
  }
  return false;
}
