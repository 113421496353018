import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loader: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const skip = request.headers.has('NoLoad') || !request.url.includes('/api');
    if (skip) {
      const newHeaders = request.headers.delete('NoLoad');
      request = request.clone({ headers: newHeaders });
    } else {
      this.loader.startLoad();
      // console.log(`Start: ${request.url} - ${this.loader.processes}`);
    }
    const stopLoad = (res: any) => {
      if (!skip) {
        this.loader.stopLoad();
        // console.log(`Stop: ${request.url} - ${this.loader.processes}`);
      }
      return res;
    };
    return next.handle(request).pipe(
      // Cannot use finalize because not all requests are wrapped in an
      // Observable which actually completes when request is done.
      // Some observables are made to be "hot" and will not complete
      // before component or service which uses them are destroyed.
      catchError(res => throwError(() => stopLoad(res))),
      map(res => stopLoad(res))
    );
  }
}
