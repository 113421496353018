export class Cookie {
  static clear() {
    Array.from(Cookie.list()).forEach(([name]) => Cookie.remove(name));
  }

  static list() {
    return document.cookie.split(';').reduce((acc, c) => {
      if (c === '') return acc;
      const [name, value] = c.split('=');
      acc.set(name.trim(), value.trim());
      return acc;
    }, new Map<string, string>());
  }

  static get(name: string): string {
    const [key, value] = Array.from(Cookie.list().entries()).find(([k, v]) => k.startsWith(name)) || ['', ''];
    return value;
  }

  static set(name: string, value: string) {
    document.cookie = `${name}=${value}; Path=/;`;
  }

  static remove(name: string) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}
