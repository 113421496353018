import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export function createTranslateLoader(http: HttpClient, catalog: string | string[] = 'i18n') {
  return new TranslationLoader(http, catalog);
}

/**
 * This is our own custom rolled Translate loader, enhanced for our needs from
 * https://github.com/ngx-translate/http-loader/blob/master/projects/ngx-translate/http-loader/src/lib/http-loader.ts
 *
 * This will include the language packs loaded from categories in Sanity CMS.
 */
export class TranslationLoader implements TranslateLoader {
  prefix!: string[];
  suffix = '.json';

  constructor(private http: HttpClient, prefix?: string | string[], suffix?: string) {
    if (prefix) this.prefix = Array.isArray(prefix) ? prefix : [prefix];
    if (suffix) this.suffix = suffix;
  }

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<{ [key: string]: string }> {
    return combineLatest(
      this.prefix.map(p => {
        return this.http
          .get<{ [key: string]: any }>(`/lang/${p}/${lang}${this.suffix}`, { headers: { NoLoad: 'true' } })
          .pipe(catchError(() => of({})));
      })
    ).pipe(map(([s1, s2]) => Object.assign(s1, s2)));
  }
}
