import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

export type KeyboardActionHandler = () => any;
export interface KeyCombination {
  ctrlKey?: boolean;
  shiftKey?: boolean;
  altKey?: boolean;
  key: string;
}
export interface KeyboardConfig extends KeyCombination {
  position?: TooltipPosition;
  disableKeys?: KeyCombination[];
  disableWhenFocused?: HTMLElement;
  titleText?: string;
  titleElement?: HTMLElement;
}

@Directive({
  selector: '[bsKeyboard]',
  standalone: true,
})
export class KeyboardDirective extends MatTooltip implements OnInit, OnChanges, OnDestroy {
  @Input('bsKeyboard') config!: KeyboardConfig;
  @Input() handler?: KeyboardActionHandler;
  isCommandKeyActive = false;
  element: ElementRef<HTMLElement> = inject(ElementRef<HTMLElement>);
  translate = inject(TranslateService);

  titleElement: HTMLElement = this.element.nativeElement;
  title = '';

  subscrtiptions: Subscription[] = [];

  ngOnInit(): void {
    this.subscrtiptions.push(this.translate.onLangChange.subscribe(() => this.createTooltip()));
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.config?.currentValue) {
      let key = this.config.key;
      // prettier-ignore
      switch (key) {
        case 'ArrowLeft': key = '←'; break;
        case 'ArrowRight': key = '→'; break;
        case 'ArrowUp': key = '↑'; break;
        case 'ArrowDown': key = '↓'; break;
      }
      this.position = this.config.position || 'above';
      this.disabled = true;

      this.message = key.toUpperCase();
      if (this.config.titleElement) this.titleElement = this.config.titleElement;
      if (this.config.titleText) this.createTooltip();
    }
  }

  ngOnDestroy(): void {
    this.subscrtiptions.forEach(s => s.unsubscribe());
  }

  createTooltip() {
    if (this.config.titleText) {
      const title = this.translate.instant(this.config.titleText);
      const keyboard = [];
      if (this.config.ctrlKey) keyboard.push('CTRL');
      if (this.config.altKey) keyboard.push('ALT');
      if (this.config.shiftKey) keyboard.push('SHIFT');
      keyboard.push(`"${this.config.key.toUpperCase()}"`);
      this.title = `${title}\n (${keyboard.join(' + ')})`;
      this.titleElement.setAttribute('title', this.title);
    }
  }

  shouldBeActive(key: KeyboardEvent, config: KeyCombination = this.config) {
    const mandatoryKeys = Object.entries(config)
      .filter(([key, value]) => key.endsWith('Key') && value == true)
      .map(([key, value]) => key);
    if (
      (key.ctrlKey && !mandatoryKeys.includes('ctrlKey')) ||
      (key.shiftKey && !mandatoryKeys.includes('shiftKey')) ||
      (key.altKey && !mandatoryKeys.includes('altKey'))
    )
      return false;
    return mandatoryKeys.every(k => (key as any)[k] == true);
  }

  toggleTooltip(commandKeyActive: boolean) {
    this.disabled = !commandKeyActive;
    commandKeyActive ? this.show() : this.hide();
  }

  @HostListener('document:keydown', ['$event'])
  onKey(key: KeyboardEvent) {
    const commandKeyActive = this.shouldBeActive(key);
    this.toggleTooltip(commandKeyActive);

    // Prevent default handlers from execution if disabled keys are active
    // and optional given element is in focus
    if (this.config.disableKeys?.length) {
      if (!this.config.disableWhenFocused || this.config.disableWhenFocused.matches(':focus')) {
        this.config.disableKeys.forEach(k => {
          if (key.key === k.key) {
            key.stopPropagation();
          }
        });
      }
    }

    // Execute keyboard handler
    if (commandKeyActive && this.config.key == key.key) {
      if ('disabled' in this.element.nativeElement && this.element.nativeElement.disabled == true) {
        key.preventDefault();
        key.stopPropagation();
      } else if (this.handler != null) {
        if (this.handler() != false) {
          // Only passthrough event when handler returns false
          key.preventDefault();
          key.stopPropagation();
        }
      }
    }
  }
  @HostListener('document:keyup', ['$event'])
  onKeyUp(key: KeyboardEvent) {
    this.toggleTooltip(this.shouldBeActive(key));
  }
}
