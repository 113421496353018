import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter', standalone: true })
export class FilterPipe implements PipeTransform {
  transform(items: any[], filter: string | ((item: any) => boolean)): any[] {
    if (!items || !filter) return items;

    if (typeof filter === 'string') {
      // Filter is a string
      if (filter.indexOf('&&') > -1) {
        // Filter chaining
        const filters = filter.split('&&');
        return filters.reduce((acc, f) => this.filterString(acc, f), items);
      }
      return this.filterString(items, filter);
    } else {
      // Filter is a function
      // filter items array, items which match and return true will be
      // kept, false will be filtered out
      return items.filter(item => filter(item));
    }
  }

  filterString(items: any[], filter: string) {
    if (filter.indexOf('!=') > -1) {
      // Negate the filter and return all values not matching
      const [prop, value] = filter.split('!=');
      return items.filter(item => item[prop] !== value);
    }
    const [prop, value] = filter.split('=');
    return items.filter(item => item[prop] === value);
  }
}
