import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { LoaderService } from './loader.service';
import { fadeIn } from '../../animations/fadeIn';
import { MatTooltip } from '@angular/material/tooltip';
import { firstValueFrom, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';

const LoaderTimeout = 5000;

@Component({
  selector: 'lib-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [fadeIn],
})
export class LoaderComponent implements AfterViewInit, OnChanges, OnDestroy {
  subscriptions: Subscription[] = [];
  @ViewChild('tooltip') tooltip!: MatTooltip;

  @Input()
  isLoading = false;

  @Input() diameter = 30;

  @Input() inline = false;
  @Input() timeout = LoaderTimeout;
  @Input() timeoutExplanation = 'It is taking longer than expected to complete this task. Please be patient.';

  _isLoading = false;
  _destroyed = false;

  constructor(private loader: LoaderService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading) {
      this._isLoading = changes.isLoading.currentValue;
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.loader.isLoading$().subscribe(on => {
        this._isLoading = on;
        if (on === true) {
          // Start loading
          firstValueFrom(timer(this.timeout)).then(() => !this._destroyed && this.tooltip?.show());
        } else {
          this.tooltip?.hide();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this._isLoading = false;
    this.tooltip?.hide();
    this.tooltip?.ngOnDestroy();
    this._destroyed = true;
  }
}
